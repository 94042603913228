(function(window, $, app) {

    "use strict";

    app.Table = {

        addRow: function() {

            "use strict";

            $(document).on('click', '[data-row-add]', function(event) {

                app.Trigger.preventStop(event);

                var button = $(this),
                    name = button.data('row-add'),
                    table = $('[data-rows="' + name + '"]'),
                    template = table.find('[data-row-template]');

                table.find('[data-row-empty]').addClass('dn');

                template
                    .clone()
                    .removeAttr('data-row')
                    .removeAttr('data-row-template')
                    .appendTo(table);

            });

        },

        saveRow: function() {

            "use strict";

            var self = this;

            $(document).on('click', '[data-row-save]', function(event) {

                app.Trigger.preventStop(event);

                var button = $(this),
                    container = button.closest('tbody'),
                    wrapper = button.closest('[data-button-wrapper]'),
                    processing = wrapper.find('[data-button-processing]'),
                    url = button.data('row-save'),
                    row = button.closest('tr'),
                    values = row.find(':input').serializeArray();

                app.Button.showProcessing(button, processing);

                $.ajax({
                    url: url,
                    method: 'POST',
                    dataType: 'json',
                    data: app.Document.addToken(values),
                    success: function(data) {
                        app.Button.hideProcessing(button, processing);
                        if (data.reload) {
                            app.Document.reload();
                        } else if (data.replace) {
                            self.removeRowHtml(row, function() {
                                return container.find('[data-row]').last().after(data.replace);
                            });
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {

                        app.Button.hideProcessing(button, processing);

                        $.each(jqXHR.responseJSON, function(key, value){
                            row.find(':input[name="' + key + '"]').addClass('invalid');
                        });

                        throw new Error(errorThrown);

                    }
                });

            });

        },

        removeRowHtml: function(row, callback) {

            "use strict";

            row.fadeOut(200, function() {
                $(this).remove();
                if (typeof callback === 'function') {
                    callback();
                }
            });

        },

        inputReturn: function() {

            "use strict";

            $(document).on('keyup', '[data-row-input]', function(event) {

                var keycode = (event.keyCode ? event.keyCode : event.which);

                if( keycode == '13') {

                    app.Trigger.preventStop(event);

                    $(this).closest('tr').find('[data-row-save]').trigger('click');

                }

            });

        },

        init: function() {

            "use strict";

            this.addRow();
            this.saveRow();
            this.inputReturn();

        }

    };

})(window, window.jQuery, window.CmdSystem);