(function(window, document, $, app, undefined) {

    "use strict";

    app.Document = {

        replace: function(data) {

            "use strict";

            if (data.replace === undefined) {
                return;
            }

            $.each(data.replace, function(key, value) {

                $('[data-' + key + ']').html(value);

            });

        },

        remove: function(data) {

            "use strict";

            if (data.remove === undefined) {
                return;
            }

            $.each(data.remove, function(key, value) {

                $('[data-' + value + ']').remove();

            });

        },

        reload: function() {

            "use strict";

            window.location.reload(true);

        },

        redirect: function(url) {

            "use strict";

            window.location.href = url;

        },

        focus: function() {

            "use strict";

            $('[data-focus]').focus();

        },

        addToken: function(data) {

            "use strict";

            data.push({ name: '_token', value: $('meta[name="csrf-token"]').attr('content') });

            return data;

        }

    };

})(window, window.document, window.jQuery, window.CmdSystem);