(function(window, $, app, undefined) {

    "use strict";

    app.UploadOptions = function(obj) {

        function isEmpty(value) {

            "use strict";

            return value === undefined;

        }

        this.instance = function() {

            "use strict";

            return obj;

        };

        this.options = {
            autoDiscover: false
        };

        var self = this,
            id = obj.attr('id'),
            removeUrl = obj.data('url-remove'),
            url = obj.data('url-upload'),
            paramName = obj.data('name'),
            maxFilesize = obj.data('max-file-size'),
            uploadMultiple = obj.data('multiple'),
            maxFiles = obj.data('max-files'),
            headers = obj.data('headers'),
            acceptedFiles = obj.data('types'),
            previewTemplate = obj.data('template'),
            thumbnailWidth = obj.data('thumb-width'),
            thumbnailHeight = obj.data('thumb-height');

        function init() {

            self.options.headers = {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            };

            if (isEmpty(id)) {
                throw new Error('Undefined instance id attribute');
            }

            self.id = function() {

                "use strict";

                return id;

            };

            self.idWithHash = function() {

                "use strict";

                return '#' + id;

            };

            if (isEmpty(removeUrl)) {
                throw new Error('Undefined remove path');
            }

            self.removeUrl = function() {

                "use strict";

                return removeUrl;

            };

            if (isEmpty(url)) {
                throw new Error('Undefined upload path');
            } else {
                self.options.url = url;
            }

            if ( ! isEmpty(paramName)) {
                self.options.paramName = paramName;
            }

            if ( ! isEmpty(maxFilesize)) {
                self.options.maxFilesize = maxFilesize;
            }

            if ( ! isEmpty(uploadMultiple)) {
                self.options.uploadMultiple = true;
            }

            if ( ! isEmpty(maxFiles)) {
                self.options.maxFiles = maxFiles;
            }

            if ( ! isEmpty(headers)) {

                var headers_array = headers.split(',');

                $.each(headers_array, function(k, v) {

                    var key_value = v.split(':');

                    if (key_value.length < 2) {
                        return true;
                    }

                    self.options.headers[key_value[0]] = key_value[1];

                });

            }

            if ( ! isEmpty(acceptedFiles)) {
                self.options.acceptedFiles = acceptedFiles;
            }

            if ( ! isEmpty(thumbnailWidth)) {
                self.options.thumbnailWidth = thumbnailWidth;
            }

            if ( ! isEmpty(thumbnailHeight)) {
                self.options.thumbnailHeight = thumbnailHeight;
            }

            if ( ! isEmpty(previewTemplate)) {
                self.options.previewTemplate = app.UploadTemplates[previewTemplate]();
            }

            self.options.previewsContainer = self.idWithHash();

            self.options.init = new app.UploadEvents(previewTemplate, removeUrl, obj);

        }

        init();

    };

})(window, window.jQuery, window.CmdSystem);