(function(window, document, $, app) {

    app.Admin = {

        navigation: function() {

            "use strict";

            $('.mainTrigger').on('click', function(event) {

                event.preventDefault();

                var parent = $(this).parent('nav');

                parent.toggleClass('opened');

                if (!parent.hasClass('opened')) {

                    parent.find('.has-dropdown').removeClass('hover');

                }

            });

            $('.mainNavigation li.has-dropdown > a').on('click', function(event) {

                event.preventDefault();

                $(this).parent('li')
                    .toggleClass('hover')
                    .siblings('li.has-dropdown')
                    .removeClass('hover');

            });

            $(document).on('click', function(event) {

                var container = $('.mainNavigation li.has-dropdown');

                if (container.has(event.target).length === 0) {

                    container.removeClass('hover');

                }

            });

        },

        pagination: function() {

            "use strict";

            $('.select-pagination select').ssdSelect({
                action: 'go-to'
            });

        },

        ssdSelect: function() {

            "use strict";

            $('[data-ssd-select]').ssdSelect({
                action_attribute : 'data-ssd-select'
            });

        },

        urlPush: function(url, title, obj) {

            "use strict";

            window.history.pushState(obj, title, url);

        },

        leftNavigation: function() {

            "use strict";

            var self = this;

            $(document).on('click', '.left-navigation a', function(event) {

                event.preventDefault();
                event.stopPropagation();

                var trigger = $(this),
                    url = trigger.attr('href'),
                    text = trigger.text(),
                    target = trigger.data('target'),
                    group = trigger.siblings('a').not(trigger);

                $.each(group, function() {

                    $(this).removeClass('active');

                    $('[data-section="' + $(this).data('target') + '"]')
                        .addClass('dn');

                });

                $('[data-section="' + target + '"]').removeClass('dn');
                $(trigger).addClass('active');

                self.urlPush(url, text, { text: url });

            });

            $(document).on('change', '.select-navigation', function(event) {

                event.preventDefault();
                event.stopPropagation();

                var trigger = $(this).find('option:selected'),
                    url = trigger.val(),
                    text = trigger.text(),
                    target = trigger.data('target'),
                    group = $(this).find('option:not(:selected)');

                $.each(group, function() {

                    $(this).attr('selected', false);

                    $('[data-section="' + $(this).data('target') + '"]')
                        .addClass('dn');

                });

                $('[data-section="' + target + '"]').removeClass('dn');
                $(trigger).attr('selected', true);

                self.urlPush(url, text, { text: url });

            });

        },

        form: function() {

            "use strict";

            $('form[data-ajax-form]').ssdForm({
                extendBehaviours: {
                    clickCallReload: function() {
                        window.location.reload(true);
                    }
                }
            });

        },

        document: function() {

            "use strict";

            app.Document.focus();

        },

        sortable: function() {

            "use strict";

            $(".sort-rows").rowSorter({
                handler: 'td.sort-handler',
                tbody: true,
                onDrop: function(tbody, row, new_index, old_index) {
                    // find the table
                    // if options.tbody is true, this param will be tbody element
                    // otherwise it will be table element
                    var table = tbody.tagName === "TBODY" ? tbody.parentNode : tbody,
                        rows = $(table).find('[data-row]:not([data-row-template]):not([data-row-empty])'),
                        ids = [],
                        url = $(table).data('sort-url');

                    $.each(rows, function(index, value) {
                        ids.push($(this).data('id'));
                    });

                    $.ajax({
                        url: url,
                        method: 'POST',
                        dataType: 'json',
                        data: app.Document.addToken([{ name: 'ids', value: ids }])
                    });
                }
            });

        },

        confirmation: function() {

            "use strict";

            app.Confirmation.init();

        },

        init: function () {

            "use strict";

            this.form();
            this.navigation();
            this.leftNavigation();
            this.pagination();
            this.confirmation();
            this.ssdSelect();
            this.document();
            this.sortable();

        }

    };

})(window, window.document, window.jQuery, window.CmdSystem);
