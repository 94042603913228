(function(window, $, app) {

    "use strict";

    app.Button = {

        showProcessing: function(button, processing) {

            "use strict";

            button.addClass('dn');
            processing.removeClass('dn');

        },

        hideProcessing: function(button, processing) {

            "use strict";

            processing.addClass('dn');
            button.removeClass('dn');

        },

        clickCallReload: function() {

            "use strict";

            var self = this;

            $(document).on('click', '[data-button-click-call-reload]', function(event) {

                app.Trigger.preventStop(event);

                var button = $(this),
                    url = button.data('url'),
                    wrapper = button.closest('[data-button-wrapper]'),
                    processing = wrapper.find('[data-button-processing]');

                self.showProcessing(button, processing);

                $.ajax({
                    url: url,
                    dataType: 'json',
                    success: function(data) {
                        app.Document.reload();
                    },
                    error: function (jqXHR, textStatus, errorThrown) {

                        self.hideProcessing(button, processing);

                        throw new Error(errorThrown);
                    }
                });

            });

        },

        clickCallRedirect: function() {

            "use strict";

            var self = this;

            $(document).on('click', '[data-button-click-call-redirect]', function(event) {

                app.Trigger.preventStop(event);

                var button = $(this),
                    url = button.data('url'),
                    wrapper = button.closest('[data-button-wrapper]'),
                    processing = wrapper.find('[data-button-processing]');

                self.showProcessing(button, processing);

                $.ajax({
                    url: url,
                    dataType: 'json',
                    success: function(data) {

                        app.Document.redirect(data.redirect);

                    },
                    error: function (jqXHR, textStatus, errorThrown) {

                        self.hideProcessing(button, processing);

                        throw new Error(errorThrown);
                    }
                });

            });

        },

        init: function() {

            "use strict";

            this.clickCallReload();
            this.clickCallRedirect();

        }

    };

})(window, window.jQuery, window.CmdSystem);