(function(window, document, $, app) {

    app.Trigger = {

        preventStop: function(event) {

            "use strict";

            event.preventDefault();
            event.stopPropagation();

        },

        toggle: function() {

            "use strict";

            $(document).on('click', '.toggle', function(event) {

                app.Trigger.preventStop(event);

                var target = $(this).data('target');

                $(target).toggle();

            });

        },

        toggleDn: function() {

            "use strict";

            $(document).on('click', '.toggleDn', function(event) {

                app.Trigger.preventStop(event);

                var target = $(this).data('target');

                $(target).toggleClass('dn');

            });

        },

        toggleSingleDn: function() {

            "use strict";

            $(document).on('click', '.toggleSingleDn', function(event) {

                app.Trigger.preventStop(event);

                var obj = $(this),
                    group_id = obj.data('group'),
                    group = $('[data-group="' + group_id + '"'),
                    target = obj.data('target');

                $.each(group, function() {

                    var this_target = $(this).data('target');

                    if (this_target !== target) {
                        $($(this).data('target')).addClass('dn');
                    }

                });

                $(target).toggleClass('dn');

            });

        },

        toggleSingleDnSlideTo: function() {

            "use strict";

            $(document).on('click', '.toggleSingleDnSlideTo', function(event) {

                app.Trigger.preventStop(event);

                var obj = $(this),
                    group_id = obj.data('group'),
                    group = $('[data-group="' + group_id + '"'),
                    target = obj.data('target');

                $.each(group, function() {

                    var this_target = $(this).data('target');

                    if (this_target !== target) {
                        $($(this).data('target')).addClass('dn');
                    }

                });

                $(target).toggleClass('dn');

                if ( ! $(target).hasClass('dn')) {

                    $('html, body').animate({
                        scrollTop: $(target).offset().top - 30
                    }, 500);

                }

            });

        },

        selectRedirect: function() {

            "use strict";

            $(document).on('change', '.selectRedirect', function() {

                var value = $(this).val(),
                    url = $(this).data('url');

                window.location.href = url + value;

            });

        },

        disabledButton: function() {

            "use strict";

            $(document).on('click', '.button.disabled', function(event) {

                app.Trigger.preventStop(event);

            });

        },

        activeButton: function() {

            "use strict";

            $(document).on('click', '.activeButton', function(event) {

                app.Trigger.preventStop(event);

                var trigger = $(this),
                    wrapper = trigger.closest('[data-active-button-wrapper]'),
                    processing = trigger.next('.activeButton');

                trigger.addClass('dn');
                processing.removeClass('dn');

                $.ajax({
                    method: 'GET',
                    url: trigger.data('url'),
                    dataType: 'json',
                    success: function(data) {
                        wrapper.replaceWith(data.replace);
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        throw new Error(errorThrown);
                    }
                });

            });

        },

        init: function () {

            "use strict";

            this.toggle();
            this.toggleDn();
            this.toggleSingleDn();
            this.toggleSingleDnSlideTo();
            this.selectRedirect();
            this.disabledButton();
            this.activeButton();

        }

    };

})(window, document, jQuery, window.CmdSystem);