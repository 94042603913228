(function(window, $, app) {

    "use strict";

    app.UploadTemplates = {

        singleImage : function() {

            "use strict";

            var template = '<div data-dz-preview>';

            template += '<span class="error-message" data-dz-errormessage></span>';

            template += '<div class="progress" role="progressbar" tabindex="0" ';
            template += 'aria-valuenow="0" aria-valuemin="0" aria-valuetext="0 percent" ';
            template += 'aria-valuemax="100">';
            template += '<span class="progress-meter" data-dz-uploadprogress>';
            template += '<span class="progress-meter-text">0%</span>';
            template += '</span>';
            template += '</div>';

            template += '<div>';

            return template;

        }

    };

})(window, window.jQuery, window.CmdSystem);