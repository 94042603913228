(function(window, $, app) {

    "use strict";

    app.UploadEvents = function(previewTemplate, removeUrl, instance) {

        switch(previewTemplate) {

            case 'singleImage':
                return new app.SingleImageEvents(removeUrl, instance);
                break;

            default:
                throw new Error('Invalid event case');

        }

    };

})(window, window.jQuery, window.CmdSystem);