(function(window, document, $, app) {

    app.Confirmation = {

        showOverlay: function() {

            "use strict";

            $('[data-ssd-confirm-overlay]').addClass('show');

        },

        hideOverlay: function() {

            "use strict";

            $('[data-ssd-confirm-overlay]').removeClass('show');

        },

        overlay: function() {

            "use strict";

            var self = this;

            $('[data-ssd-confirm-overlay]').on('click', function(event) {

                app.Trigger.preventStop(event);

                self.hideConfirmation();

            });

        },

        hideConfirmation: function() {

            "use strict";

            $('[data-ssd-confirm]')
                .removeClass('active')
                .find('[data-ssd-confirm-block]')
                .removeClass('show')
                .find('nav span')
                .removeAttr('ids')
                .removeAttr('url');

            this.hideOverlay();

        },

        buttonNo: function() {

            "use strict";

            var self = this;

            $(document).on('click', '[data-ssd-confirm-no]', function(event) {

                app.Trigger.preventStop(event);

                self.hideConfirmation();

            });

        },

        appendToUrl: function(url, string) {

            "use strict";

            if (url.includes('?')) {
                return url + '&' + string;
            }

            return url + '?' + string;
        },

        remove: function(params) {

            "use strict";

            var url = params.trigger.data('url');

            if (typeof url === 'undefined') {

                return params.trigger.closest('tr').fadeOut(200, function() {
                    $(this).remove();
                });
            }

            this.showOverlay();

            if (typeof params.ids !== 'undefined') {

                url = this.appendToUrl(
                    url,
                    $.param({ids: params.ids})
                );

            }

            $('[data-ssd-confirm-block="remove"]')
                .addClass('show')
                .find('[data-ssd-confirm-yes]')
                .data('url', url);

            $('[data-ssd-confirm]').addClass('active');

        },

        threeStateCheckbox: function() {

            "use strict";

            var self = this;

            $(document).ssdThreeStateCheckbox({

                clickCallback : function(ids, trigger) {

                    var method = trigger.data('action');

                    self[method]({ trigger: trigger, ids: ids });

                }

            });

        },

        buttonRemove: function() {

            "use strict";

            var self = this;

            $(document).on('click', '[data-button-remove-single]', function(event) {

                app.Trigger.preventStop(event);

                self.remove({ trigger: $(this) });

            });

        },

        init: function () {

            "use strict";

            this.threeStateCheckbox();
            this.buttonRemove();
            this.buttonNo();
            this.overlay();

        }

    };

})(window, window.document, window.jQuery, window.CmdSystem);