(function(window, $, app) {

    "use strict";

    app.Editor = {

        ckEditor: function() {

            "use strict";

            $('.editor').each(function() {
                $(this).ckeditor();
            });

        },

        init: function() {

            "use strict";

            this.ckEditor();

        }

    };

})(window, window.jQuery, window.CmdSystem);