(function(window, document, $, Dropzone, app) {

    "use strict";

    app.Upload = {

        removeButton: function(options) {

            "use strict";

            $(document).on(
                'click',
                '[data-remove-button="' + options.idWithHash() + '"]',
                function(event) {

                    event.preventDefault();
                    event.stopPropagation();

                    var trigger = $(this),
                        processing = trigger.closest('[data-button-wrapper]').find('[data-processing-button]');

                    trigger.addClass('dn');
                    processing.removeClass('dn');

                    $.get(options.removeUrl()).done(function(data) {

                        $(options.idWithHash()).html(data.image);

                        processing.addClass('dn');
                        trigger.removeClass('dn');

                    });

                }
            );

        },

        init: function() {

            "use strict";

            var self = this;

            $('[data-upload]:not(.dirty)').each(function() {

                var uploadOptions = new app.UploadOptions($(this));

                var dropZone = new Dropzone(
                    uploadOptions.idWithHash(),
                    uploadOptions.options
                );

                self.removeButton(uploadOptions);

            });

            $(document).on('click', '[data-upload-button]', function(event) {

                event.preventDefault();
                event.stopPropagation();
                
                var trigger = $(this),
                    target = trigger.data('upload-button');

                $(target).trigger('click');

            });

        }

    };

})(window, window.document, window.jQuery, window.Dropzone, window.CmdSystem);