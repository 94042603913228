(function(window, $, app) {

    "use strict";

    app.SingleImageEvents = function(removeUrl, instance) {

        "use strict";

        return function() {

            this.on("addedfile", function (file) {

                instance.addClass('dirty');

            });

            this.on('uploadprogress', function (file, progress, bytesSent) {

                file.previewElement
                    .querySelector(".progress-meter-text")
                    .innerHTML = progress + '%';

                file.previewElement
                    .querySelector(".progress")
                    .setAttribute('aria-valuetext', progress + ' percent');

                file.previewElement
                    .querySelector(".progress")
                    .setAttribute('aria-valuenow', progress);

            });

            this.on('complete', function (file) {

                instance.removeClass('dirty');

            });

            this.on('canceled', function (file) {

                this.removeFile(file);
                $.get(removeUrl);

            });

            this.on('success', function (file, data) {

                instance.html(data.tag);

            });

            this.on('error', function (file, errorMessage) {

                console.log(errorMessage);

            });

        };

    };

})(window, window.jQuery, window.CmdSystem);